import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
const StyledDiv = styled.div`
  font-size: 1.2rem;
  margin-top: 15px;

  
`;
const index = () => {
  return (
    <Container>
      <StyledDiv><b>I need to modify my reservation?</b></StyledDiv>
<StyledDiv>Click on the View or Cancel Reservation on the reservation widget. You can also call us at 888-2rentvan or email us at support@planetvanrentals.com anytime to help update or change your reservation.</StyledDiv>
<StyledDiv><b>I want to add an additional service (i.e additional driver, gps, fuel.)</b></StyledDiv>
<StyledDiv>Please email support@planetvanrentals.com with what services you would like added. If you are added an additional driver, please have a copy of the persons Drivers license as it will be needing to add them on.</StyledDiv>
<StyledDiv><b>I want to change my coverage options?</b></StyledDiv>
<StyledDiv>You can change your coverage options online at any time, 48 hours before your rental. However, if you need to make a one-time change, please email support@planetvanrentals.com and we will update your rental. This rental will require a one-time signature.</StyledDiv>
<StyledDiv><b>I am unable to pick up a van at your location, do you provide van delivery services?</b></StyledDiv>
<StyledDiv>Yes, if you need our vans delivered, we will gladly take them to you. We deliver vans in Florida. We want to be as flexible as you need us to be. Let us know what your plans are and we will work it out for you (additional drop off and pick up fees may apply).</StyledDiv>
<StyledDiv><b>Can I rent the van and drop it off somewhere else?</b></StyledDiv>
<StyledDiv>Yes, you can rent a van and drop it off any of our locations. We also offer pick ups from other locations. We want to be as flexible as you need us to be. Let us know what your plans are and we will work it out for you.</StyledDiv>
<StyledDiv><b>I found another van for a cheaper price, will you match or beat that price?</b></StyledDiv>
<StyledDiv>Yes we will! You must provide proof of the van type and specific dates of a competitors rate. We will be happy to match or beat that price to earn your business.</StyledDiv>
<StyledDiv><b>Do you provide airport pick up and drop off shuttle services?</b></StyledDiv>
<StyledDiv>Yes we do, just call us at 888-2rentvan and we'll arrange for a pick up or drop off. Specific pick up locations can be found on our Locations page.</StyledDiv>
<StyledDiv><b>I'm not sure what size van I should rent, can you help me with that?</b></StyledDiv>
<StyledDiv>Absolutely, just give us a call, tell us what your plans are and we'll help you determine the best van for your needs. We want to make sure you are comfortable with the van we provide. Please do not hesitate to contact us anytime, we are happy to help.</StyledDiv>
<StyledDiv><b>Is there an age requirement?</b></StyledDiv>
<StyledDiv>For drivers age 18 to 24, a surcharge of $25.00 per day applies.</StyledDiv>
<StyledDiv><b>Do you offer any driver or chauffeur services?</b></StyledDiv>
<StyledDiv>Yes we do, when you make your reservation, please note you'd like a driver. We will contact you to confirm your itinerary and discuss further details to ensure we cover all your needs.</StyledDiv>

    </Container>
  );
};

export default index;
