import React from "react";
import styled from "styled-components";
import styles from "../Details/Details.module.scss";
import check from "../img/chkmark.jpg";
import { PlanetContext } from "../containers/PlanetProvider";
import { Container, Input, Row, Col } from "reactstrap";

const ResultsBox = styled.div`
  width: 100%;
  height: 300px;
  border: 1px solid #e6e7eb;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  padding-right: 10px;
`;
const Inc = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;
const size = {
  FVAR: "15 Passenger Van",
  IVAR: "12 Passenger Van",
  SVAR: "11 Passenger Van",
};
const Van = ({v, ...props}) => {

      const calculatePrice = (discount) => {

        const price = props.state.price ? props.state.price : v.price
        const calcPrice = discount ? Math.round( price * discount * 100 ) / 100 : price


        return calcPrice.toLocaleString(
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
            {
              style: "currency",
              currency: "USD",
            } )


      };


      const inclusions = v.inclusions.map( (inc, b) => {
        return (
            <div key={b} className={styles.inc2}>
              <img src={check} alt=""/>
              {inc}
            </div>
        );
      } );
      return (
          <div>
            <Row>
              <Col xs={4}>
                <div className={styles.sizeheader}>{size[v.acriss]}</div>
                {" "}
                <div className={styles.sizename}> {v.vehName}</div>
              </Col>
              <Col xs={4} className={styles.image}>
                {" "}
                <img src={v.url} alt=""/>
              </Col>
              <Col xs={4}>
                <div className={styles.included2}>
                  <div className={styles.incholder}>
                    <Inc>Included In Price:</Inc> {inclusions}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={styles.pricerow}>
              <Col xs={12}>
                <div className={styles.priceday}>
                  US$
                  {Math.floor( v.price / v.rentaldays )} <span>/Day</span>
                </div>
                {" "}
              </Col>{" "}
              <Col xs={12}>
                <div className={styles.priceholder}>
                  <div> Total w/taxes & fees: <span> ${calculatePrice()}</span></div>
                  <div>Pay Now 3% Discount
                    : <span> {calculatePrice(.97)}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
      );
    }
;

export default Van;
