import React, { Component } from "react";
import { PlanetContext } from "../containers/PlanetProvider";
import styles from "../Details/Details.module.scss";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
const StyledButton = styled.button`
  background: #ff6205 !important;
  font-size: 30px !important;
  font-weight: 500;
  width: 100%;
  color: white;
  border-radius: 8px;
`;
const StyledTitle = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;
const KEY = "6LcOOZwUAAAAAIXJRkn47TWeU72BxRC5bV2QnOlb";
export default class ModifyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      disabled: true,
    };
    this._reCaptchaRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  static contextType = PlanetContext;
  handleChange = value => {
    this.setState({ value });
    if (value) this.setState({ disabled: false });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };
  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };
  handleSubmit = e => {
    e.preventDefault();
    const firstname = this.context.firstName;
    const lastname = this.context.lastName;
    const email = this.context.email;
    const phone = this.context.phone;

    if (
      firstname.length > 0 &&
      lastname.length > 0 &&
      email.length > 0 &&
      phone.length > 0
    ) {
      this.context.sendMessage(e);
    }
    if (
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      !phone.length === 0
    ) {
      this.toggle();
    }
  };

  render() {
    if (!this.context.emailSent) {
      return (
        <Form>
          <Row form>
            <Col xs={12}>
              {" "}
              <StyledTitle>{this.props.title}</StyledTitle>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  value={this.context.firstName}
                  onChange={this.context.setValue.bind(this, "firstName")}
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="First Name"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  value={this.context.lastName}
                  onChange={this.context.setValue.bind(this, "lastName")}
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  value={this.context.email}
                  onChange={this.context.setValue.bind(this, "email")}
                  type="email"
                  name="email"
                  id="Email"
                  placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  value={this.context.phone}
                  onChange={this.context.setValue.bind(this, "phone")}
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                />
              </FormGroup>
            </Col>{" "}
            <Col xs={12}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  value={this.context.subject}
                  onChange={this.context.setValue.bind(this, "subject")}
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder={this.props.placeholder}
                />
              </FormGroup>
            </Col>
            <Col sm={12} className={styles.textarea}>
              <FormGroup>
                <Label for="special">Message</Label>
                <Input
                  type="textarea"
                  onChange={this.context.setValue.bind(this, "message")}
                  value={this.context.message}
                  name="message"
                  id="special"
                />
              </FormGroup>
            </Col>{" "}
          </Row>{" "}
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="light"
            ref={this._reCaptchaRef}
            sitekey={KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
          <StyledButton
            disabled={this.state.disabled}
            onClick={this.handleSubmit}>
            Send
          </StyledButton>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            centered
            className={this.props.className}>
            <ModalHeader toggle={this.toggle}>
              <h2> Missing Required Information</h2>
            </ModalHeader>
            <ModalBody>
              <h3>
                {" "}
                Firstname, lastname, email and phone are required to make
                modification
              </h3>
            </ModalBody>
          </Modal>
        </Form>
      );
    } else {
      return <div>{this.context.emailConfirmation}</div>;
    }
  }
}
