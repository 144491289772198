import React from "react";
import styled from "styled-components";
import { Input, Row, Col } from "reactstrap";
import styles from "./Details.module.scss";
import { device } from "../components/device";
const Box = styled.div`
  width: 100%;
  min-height: 300px;
  border: 1px solid #e6e7eb;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  padding-right: 10px;
`;
const ExtHolder = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
const ExtItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
`;
const Desc = styled(Col)`
  padding-left: 5px;
  font-size: 14px;
  @media ${device.laptop} {
    font-size: 20px;
  }
`;
const StRow = styled(Row)`
  margin: 10px 10px;
  font-size: 20px;
  width: 100%;
`;
const ImgHolder = styled.div`
  height: auto;
  width: auto;
  img {
    height: 30px;
  }
`;
const Extras = ({ extras, toggle, state }) => {
  const extList = Object.values(extras).map((ext, i) => {
    return (
      <StRow key={i}>
        <Col xs={{ size: 2 }}>
          <ImgHolder>
            <img src={ext.url} alt="" />
          </ImgHolder>
        </Col>
        <Desc sm={{ size: 4, offset: 1 }} xs={{ size: 6 }}>
          {ext.desc}
        </Desc>
        <Col
          className={styles.cost}
          xs={{ size: 2 }}
          sm={{ size: 2, offset: 1 }}>
          ${ext.cost}/day{" "}
        </Col>
        <Col
          className={styles.extrabox}
          xs={{ size: 1, offset: 1 }}
          sm={{ size: 1, offset: 0 }}>
          {" "}
          <Input
            type="checkbox"
            checked={state[ext.code]}
            onChange={e => {
              toggle(e, ext.code);
            }}
            value={ext.code}
          />
        </Col>
      </StRow>
    );
  });

  return (
    <div>
      <ExtHolder>{extList}</ExtHolder>
    </div>
  );
};

export default Extras;
