import React from "react";
import styled from "styled-components";
import styles from "../Results/Results.module.scss";
import { device } from "../components/device";
const Location = styled.div`
  width: 100%;
  color: white;
  background: gray;
  height: 85px;
  margin-bottom:10px
   @media ${device.tablet} {
    min-height: 150px;
   }
`;
const LocationHeader = ({ results }) => {
  return (
    <Location>
      <div className={styles.locholder}>
        <div className={styles.locationd1} />
        <div className={styles.locationp}>
          <div className={styles.holder}>
            <div className={styles.title}> Pick up location:</div>
            <div className={styles.name}> {results.pickup.name}</div>
            <div className={styles.openhours}>
              {" "}
              {results.vehicles[0].pudate} {results.vehicles[0].putime}
            </div>
          </div>
        </div>
        <div className={styles.locationd}>
          <div className={styles.holder}>
            <div className={styles.title}> Drop-off location:</div>
            <div className={styles.name}> {results.dropoff.name}</div>
            <div className={styles.openhours}>
              {results.vehicles[0].dodate} {results.vehicles[0].dotime}
            </div>
          </div>
        </div>
        <div className={styles.location4} />
      </div>
    </Location>
  );
};

export default LocationHeader;
