import React from "react";
import styled from "styled-components";
import ModifyForm from "../components/ModifyForm";
import Contact from "../img/Contact.jpg";
import Operator from "../img/Operator.jpg";
const StyledDiv = styled.div`
  font-weight: 500;
  width: 100%;

  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const StyledHeading = styled.div`
  width: 100%;
  display: flex;
  font-size: 24px;
  margin-bottom: 10px;
  justify-content: center;
  img {
    border-radius: 8px;
  }
`;
const StyledHeading1 = styled.div`
  width: 100%;
  display: flex;
  font-size: 24px;
  margin-bottom: 50px;
  justify-content: center;
  justify-content: center;
  img {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
const StDiv = styled.div`
  font-size: 22px;
  text-align: center;
  color: #306cea;
  a {
    color: #306cea;
  }
`;
const index = () => {
  return (
    <StyledDiv>
      <StyledHeading>
        <img src={Operator} alt="" />
      </StyledHeading>
      <ModifyForm placeholder="Subject" title="Contact Us " />{" "}
      <StyledHeading1>
        <img src={Contact} alt="" />
      </StyledHeading1>{" "}
      <StDiv>
        <a
          href="mailto:support@planetvanrentals.com"
          rel="noopener noreferrer"
          target="_blank">
          Email: support@planetvanrentals.com
        </a>
        <div> Corporate Headquaters: 14707 S.Dixie Hwy.,Miami, Fl, USA</div>
      </StDiv>
    </StyledDiv>
  );
};

export default index;
