import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
// // import _ from "lodash";
// import moment from "moment";
import moment from "moment";

export const PlanetContext = React.createContext();

class PlanetProvider extends Component {
  state = {
    results: undefined,
    loading: false,
    displayHome: true,
    displayResults: false,
    pickedVan: undefined,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    rentalDays: undefined,
    notifications: "null,",
    showModal: false,
    specialinstructions: "",
    message: "", showAlert: false, alert: undefined,
    is: false,
    ts: false,
    GPS: false, loading1: false,
    CDW: false,
    bs: false, ckout: false, dispatched: false,
    sun: false, showProblem: false,
    handleReserveButton: e => this.handleReserveButton( e ),
    getQuote: search => this.getQuote( search ),
    setValue: (field, e) => this.setValue( field, e ),

    onClickReserve: (e, v) => this.onClickReserve( e, v ),
    sendMessage: () => this.sendMessage(),
    makeReservation: (s) => this.makeReservation( s ),
    makeReservationL: () => this.makeReservationL,
    toggle: (e, val) => this.toggle( e, val ),
    updateState: (obj) => this.updateState( obj )

  }
  ;
  onClickReserve = (e, v) => {
    this.setState( {pickedVan: v} );
    this.props.history.push( "/details" );
  };
  setValue = (field, e) => {
    var object = {};
    object[field] = e.target.value;
    this.setState( object );
  };
  handleReserveButton = e => {
    this.makeReservationL();
  };
  getRentalDays = s => {
    const {
      startDate: pudate,
      ptime: putime,
      endDate: dodate,
      dtime: dotime,
    } = s;

    const day2 = moment( `${pudate}T${putime}` );
    const day1 = moment( `${dodate}T${dotime}` );
    const days = Math.ceil( day1.diff( day2, "hours" ) / 24 );
    this.setState( {rentalDays: days} );
  };
  clearData = () => {
    this.setState( {
      firstName: "",
      lastName: "",

      email: "",
      phone: "",
      ploc: this.state.pickedVan.ploc,
      dloc: this.state.pickedVan.dloc,
      specialinstructions: "",
      rateref: "",
    } );
  };
  makeReservation = s => {
    let ckout = this.state.ckout
    console.log( '------mamkereservations--------------' )
    console.log( s )
    console.log( '----------------------------' )
    if (ckout) return;
    console.log( '-----------makereservation---------' )
    console.log( s )
    console.log( '----------------------------' )
    this.setState( {loading1: true, showProblem: false, ckout: true} );

    const requestData = {
      username: "react",
      password: "react",
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      pudate: this.state.pickedVan.pudate,
      putime: this.state.pickedVan.putime,
      dodate: this.state.pickedVan.dodate,
      dotime: this.state.pickedVan.dotime,
      age: 30,
      email: this.state.email,
      phone: this.state.phone,
      ploc: this.state.pickedVan.ploc,
      dloc: this.state.pickedVan.dloc,
      specialinstructions: this.state.specialinstructions,
      rateref: this.state.pickedVan.rateref,
      resConfirmation: null,
      message: "",
      subject: "",
      emailSent: false,
      emailConfirmation: null,
      extras: this.state.chosenExtras,
      addextras: this.state.addextras,
      cctransaction: s
    };
    console.log( '----------------------------' )
    console.log( requestData )
    console.log( '----------------------------' )
    const planetcon = {
      url: "https://autorentalgateway.com/api/planet/checkout",
      method: "post", // default

      data: requestData,
    };
    axios( planetcon )
        .then( response => response.data )
        .then( rest1 => {
          console.log( '---------rest1 -------------' )
          console.log( rest1 )
          console.log( '----------------------------' )
          this.setState( {
            resConfirmation: rest1,
            loading1: false, ckout: false
          } );
          if (rest1.status == 'approved') {
            this.clearData();
            this.props.history.push( "/confirmation" );
          } else {
            const message =rest1.details.cctransaction.createTransactionResponse.transactionResponse.errors.error.errorText
            // const message = 'Your credit card has been declined'
            console.log( '----------------------------' )
            console.log( message )
            console.log( '----------------------------' )
            this.setState( {
              alert: `There has been a problem with your transaction. ${message} Contact Support 
                or Retry your payment below`, showAlert: true
            } );
            setTimeout(  () => {
              this.setState( {alert: null, showAlert: false} )
            },4000 )
          }


        } )
        .catch( error => {
          this.setState( {
            loading1: false, ckout: false
          } );
        } );
  };
  makeReservationL = s => {
    let ckout = this.state.ckout
    if (ckout) return;

    this.setState( {loading: true, showProblem: false, ckout: true} );

    const requestData = {
      username: "react",
      password: "react",
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      pudate: this.state.pickedVan.pudate,
      putime: this.state.pickedVan.putime,
      dodate: this.state.pickedVan.dodate,
      dotime: this.state.pickedVan.dotime,
      age: 30,
      email: this.state.email,
      phone: this.state.phone,
      ploc: this.state.pickedVan.ploc,
      dloc: this.state.pickedVan.dloc,
      specialinstructions: this.state.specialinstructions,
      rateref: this.state.pickedVan.rateref,
      resConfirmation: null,
      message: "",
      subject: "",
      emailSent: false,
      emailConfirmation: null,
      extras: this.state.chosenExtras,
      addextras: this.state.addextras,

    };
    console.log( '----------------------------' )
    console.log( requestData )
    console.log( '----------------------------' )
    const planetcon = {
      url: "https://autorentalgateway.com/api/planet/reservations",
      method: "post", // default

      data: requestData,
    };
    axios( planetcon )
        .then( response => response.data )
        .then( rest1 => {
          console.log( '---------rest1 -------------' )
          console.log( rest1 )
          console.log( '----------------------------' )
          this.setState( {
            resConfirmation: rest1,
            loading: false, ckout: false
          } );

          this.clearData();
          this.props.history.push( "/confirmation" );

        } )
        .catch( error => {
          this.setState( {
            loading: false, ckout: false
          } );
        } );
  };

  async toggle(e, val) {
    let st = this.state[val];
    st = !st;
    await this.setState( {[val]: st} );
    this.recalculate();
  }

  updateState = obj => {
    this.setState( obj );
  };

  recalculate() {
    const {rentalDays} = this.state;
    const price = this.state.pickedVan.price;
    const chosenExtras = Object.keys( this.state.results.extras ).filter(
        k => this.state[k] === true,
    );
    const extras =
        Object.keys( this.state.results.extras )
              .filter( k => this.state[k] === true )
              .map( it => {
                return this.state.results.extras[it];
              } )
              .reduce( (total, it) => {
                return total + it.cost;
              }, 0 ) * rentalDays;
    const newprice = (price + extras)

    this.setState( {chosenExtras, price: newprice, addextras: extras} );
  }

  sendMessage = async s => {
    const firstname = this.state.firstName;
    const lastname = this.state.lastName;
    const name = `${firstname}  ${lastname}`;
    const requestData1 = {
      email: this.state.email,
      phone: this.state.phone,
      subject: this.state.subject,
      body: this.state.message,
      name,
    };
    const planetcon1 = {
      url: "https://autorentalgateway.com/contact/planet",
      method: "post", // default
      data: requestData1,
    };
    await axios( planetcon1 )
        .then( response1 => response1.data )
        .then( rest2 => {
          this.setState( {
            emailConfirmation: rest2.message,
            emailSent: true,
          } );
        } )
        .catch( error => {} );
  };
  getQuote = async s => {
    this.getRentalDays( s );
    this.setState( {loading: true} );
    const search = {
      pudate: s.startDate,
      putime: s.ptime,
      dodate: s.endDate,
      dotime: s.dtime,
      age: 30,
      ploc: s.pickloc,
      dloc: s.droploc,
    };
    const request = {
      url: `https://www.autorentalgateway.com/api/planet/quotes`,
      method: "post", // default
      // headers: { "cache-control": "no-cache" },
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "cache-control": "no-cache",
      },
      data: search,
    };
    await axios( request )
        .then( response => response.data )
        .then( rest => {
          this.setState( {
            results: rest,
            loading: false,
            displayHome: false,
            displayResults: true,
          } );
          this.props.history.push( "/results" );
        } )
        .catch( error => {
          this.setState( {
            loading: false,
          } );
        } );
  };

  render() {
    return (
        <PlanetContext.Provider
            value={{
              ...this.state,
            }}>
          {this.props.children}
        </PlanetContext.Provider>
    );
  }
}

const PlanetConsumer = PlanetContext.Consumer;
export { PlanetConsumer };
export default withRouter( PlanetProvider );
