import React, { Component } from "react";
import styled from "styled-components";
import styles from "../Home/Home.module.scss";

import DatePicker from "react-datepicker";
import { PlanetContext } from "../containers/PlanetProvider";
import moment from "moment";
import { device } from "./device";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Input, Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Timepicker from "./TimePicker";
import Loader from "./Loader";

const StyledContainer = styled( Container )`
  background: lightgray;
  min-height: 300px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 8px;
   @media ${device.mobileL} {
  min-height: 160px;
max-width:900px!important;
   }
`;
const StyledLabel = styled.div`
  font-size: 1.1rem;
  margin-top: 6px;
`;
const StyledDate = styled( DatePicker )`
  /* margin-top: 10px; */
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;

  font-weight: 400;
  line-height: 1.5;
font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  // @media ${device.mobileL} {
  //   font-size: 16px;
  // }

  /* display: flex; */
  /* width: 100px; */
`;
const StyledButton = styled.button`
  background: #ff6205 !important;
  height: 100%;
  font-size: 30px;
  width: 100%;
  color: white;
  font-weight: 800;

  border-radius: 8px;
  @media ${device.mobileL} {
    font-size: 24px;
  }
  @media ${device.tablet} {
    font-size: 21px;
  }
  @media ${device.laptop} {
    font-size: 24px;
     height: 75%;
  }
`;
const StyledTimepicker = styled( Timepicker )`

  select{
  
  
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;}
  @media ${device.mobileL} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 21px;
  }
  @media ${device.laptop} {
    font-size: 30px;
  }

`;
const StyledRow = styled( Row )`
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
`;

class SelectBox extends Component {
  static contextType = PlanetContext;
  state = {
    locations: [
      {value: "MIAKE", text: "Miami-Kendall-Pinecrest"},
      {value: "TMB", text: "Kendall - Tamiami Executive Airport (TMB)"},
      {value: "MIA", text: "Miami International Airport (MIA)"},
      {value: "OPF", text: "Opa - locka Executive Airport (OPF)"},
      {value: "FLL", text: "Fort Lauderdale International Airport (FLL)"},
      {value: "RSW", text: "Fort Myers International Airport (RSW)"},
      {value: "JAX", text: "Jacksonville International Airport (JAX)"},
      {value: "MCO", text: "Orlando International Airport (MCO)"},
      {value: "PNS", text: "Pensacola International Airport (PNS)"},
      {value: "MIAPE", text: "Port Everglades(Cruise terminal)"},
      {value: "MIAPM", text: "Port of Miami(Cruise terminal)"},
      {value: "SFB", text: "Sanford International (SFB)"},
      {
        value: "PIE",
        text: "St.Petersburg / Clearwater International Airport(PIE)",
      },
      {value: "TPA", text: "Tampa International Airport (TPA)"},
      {value: "PBI", text: "West Palm Beach Airport (PBI)"},
    ],
    pickloc: "MIAKE",
    droploc: "",
    ptime: "10:00:00",
    dtime: "10:00:00",
    minDate: new Date(),
    // startDate: "2019-02-26",
    // endDate: "2019-02-28",
    startDate: new Date(),
    endDate: new Date(),
    modal: false,
    toggle: () => {
      this.toggle();
    },
  };

  componentDidMount() {
    this.setEndDate();
  }

  setEndDate = () => {
    const end = moment( this.state.startDate )
        .add( 3, "days" )
        .format( "YYYY-MM-DD" );
    this.setState( {endDate: new Date( end )} );
  };
  toggle = () => {
    this.setState( prevState => ({
      modal: !prevState.modal,
    }) );
  };
  handleChange = (e, type) => {
    this.setState( {[type]: e.target.value} );
  };
  locationOptions = () => {
    const opt = this.state.locations.map( (loc, i) => {
      return (
          <option key={i} value={loc.value}>
            {loc.text}
          </option>
      );
    } );
    return opt;
  };
  handleChangeDate = (date, state) => {
    if (state === "startDate") {
      this.setState(
          {
            startDate: date,
          },
          this.setEndDate,
      );
    } else {
      this.setState( {
        [state]: date,
      } );
    }
  };
  btnClick = () => {
    let {pickloc, droploc, startDate, endDate, ptime, dtime} = this.state;
    droploc = droploc.length === 0 ? pickloc : droploc;
    startDate = moment( startDate ).format( "YYYY-MM-DD" );
    endDate = moment( endDate ).format( "YYYY-MM-DD" );
    let mpdate = moment( `${startDate}T${ptime}` )
    let mddate = moment( `${endDate}T${dtime}` )
    if (this.state.pickloc.length > 0 && mddate > mpdate) {

      console.log( '---------checke------------' )
      console.log( mpdate < mddate )
      console.log( '----------------------------' )
      this.context.getQuote( {
        pickloc,
        droploc,
        startDate,
        endDate,
        ptime,
        dtime,
      } );
    } else {
      this.toggle();
    }
  };
  setTime = (e, name) => {
    this.setState( {[name]: e.target.value} );
  };

  render() {
    return (
        <>
          {this.context.loading && (
              <Container className={styles.spinner}>
                <Loader/>
              </Container>
          )}

          {!this.context.loading && (
              <StyledContainer>
                <Row className={styles.srow}>
                  <Col xs="12" sm="4" md={5}>
                    <Col xs="12">
                      <StyledLabel>Pick-Up Location</StyledLabel>
                    </Col>{" "}
                    <Col xs="12">
                      <Input
                          className={styles.selsize}
                          type="select"
                          value={this.state.pickloc}
                          onChange={e => this.handleChange( e, "pickloc" )}
                          name="pickloc"
                          id="pickloc">
                        <option>Pick-Up City & Location</option>
                        {this.locationOptions()}
                      </Input>
                    </Col>
                    <StyledRow>
                      <Col xs="6" sm={6} md={6}>
                        <StyledDate
                            className={styles.datepicker}
                            selected={this.state.startDate}
                            minDate={this.state.minDate}
                            withPortal
                            onChange={date =>
                                this.handleChangeDate( date, "startDate" )
                            }
                            monthsShown={1}
                        />
                      </Col>{" "}
                      <Col xs="6" sm={12} md={6}>
                        <StyledTimepicker
                            id="ptime"
                            name="ptime"
                            time={this.state.ptime}
                            onChange={this.setTime}
                        />
                      </Col>
                    </StyledRow>
                  </Col>
                  <Col xs="12" sm="4" md={5}>
                    {" "}
                    <Col xs="12">
                      <StyledLabel>Drop-off Location</StyledLabel>{" "}
                    </Col>{" "}
                    <Col xs="12">
                      <Input
                          type="select"
                          className={styles.selsize}
                          value={this.state.droploc}
                          onChange={e => this.handleChange( e, "droploc" )}
                          name="droploc"
                          id="droploc">
                        <option>Drop off Same as Pick up</option>
                        {this.locationOptions()}
                      </Input>
                    </Col>
                    <StyledRow>
                      <Col xs="6" sm={6} md={6}>
                        <StyledDate
                            className={styles.datepicker}
                            selected={this.state.endDate}
                            minDate={this.state.startDate}
                            withPortal
                            onChange={date => this.handleChangeDate( date, "endDate" )}
                            monthsShown={1}
                        />
                      </Col>{" "}
                      <Col xs="6" sm={12} md={6}>
                        <StyledTimepicker
                            id="dtime"
                            name="dtime"
                            time={this.state.dtime}
                            onChange={this.setTime}
                        />
                      </Col>{" "}
                    </StyledRow>
                  </Col>
                  <Col className={styles.btnholder} xs="12" sm="4" md={2}>
                    <StyledButton onClick={this.btnClick}>Search</StyledButton>
                  </Col>
                </Row>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    centered
                    className={this.props.className}>
                  <ModalHeader toggle={this.toggle}>
                    <h2> Missing Required Information</h2>
                  </ModalHeader>
                  <ModalBody>
                    <h3> Please Select Valid Pick-up Location, Drop-Off Location ,Pick and Drop off
                      Times </h3>
                  </ModalBody>
                </Modal>
              </StyledContainer>
          )}
        </>
    );
  }
}

export default SelectBox;
