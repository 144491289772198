import React from "react";
import styled from "styled-components";
import Map from "../components/Map";
import { Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
// import { Container } from "reactstrap";
const StyledDiv = styled.div`
  font-size: 1.2rem;
  margin: 30px 30px;
  height: 100%;
`;
const index = () => {
  return (
    <Row className="w-100">
      <Col xs={12} md={3}>
        <StyledDiv>
          <h2>Locations</h2>
          <p>Miami International Airport</p>
          <p>Fort Lauderdale International Airport</p>
          <p>Orlando International Airport</p>
          <p>Tampa International Airport</p>
          <p>Fort Myers International Airport</p>
          <p>Miami Cruise Port</p>
          <p>Fort Lauderdale Cruise Port</p>
          <p>Jacksonville International Airport</p>
          <p>Tallahasse International Airport</p>
          <p>Palm Beach International Airport</p>
          <p>Kendall Regional Airport</p>
          <p>Opa Locka Executive Airport</p> <p />
          <p>**Corporate Headquaters: 14707 S.Dixie Hwy.,Miami, Fl, USA</p>{" "}
        </StyledDiv>
      </Col>
      <Col xs={12} md={9}>
        {" "}
        <Map />
      </Col>
    </Row>
  );
};

export default index;
