import React from "react";
import styled from "styled-components";
import ModifyForm from "../components/ModifyForm";

const StyledDiv = styled.div`
  font-weight: 500;
  width: 100%;

  height: 500px;
  display: flex;
  justify-content: center;
`;
const ModifyReservations = () => {
  return (
    <StyledDiv>
      <ModifyForm
        placeholder="Reservation Number"
        title="Contact Us to Modify your Reservation"
      />
    </StyledDiv>
  );
};

export default ModifyReservations;
