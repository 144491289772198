import React, { Component } from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
class Map extends Component {
  render() {
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: 28.43344, lng: -81.30829 }}
        defaultZoom={7}>
        <Marker position={{ lat: 25.7933, lng: -80.2906 }} />
        <Marker position={{ lat: 26.07168, lng: -80.14526 }} />
        <Marker position={{ lat: 28.43344, lng: -81.30829 }} />{" "}
        <Marker position={{ lat: 25.77873, lng: -80.17546 }} />
        <Marker position={{ lat: 26.0987, lng: -80.12105 }} />
        <Marker position={{ lat: 30.33218, lng: -81.65565 }} />{" "}
        <Marker position={{ lat: 30.43826, lng: -84.28073 }} />
        <Marker position={{ lat: 26.68847, lng: -80.09013 }} />
        <Marker position={{ lat: 25.95223, lng: -80.42955 }} />{" "}
        <Marker position={{ lat: 25.6737, lng: -80.37305 }} />
        <Marker position={{ lat: 27.9835, lng: -82.5371 }} />
        <Marker position={{ lat: 26.6406, lng: -81.8723 }} />
      </GoogleMap>
    ));
    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `600px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}
export default Map;
