import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Container, Alert } from "reactstrap";
import { PlanetContext } from "../containers/PlanetProvider";
import Van from "../Results/Van";
import styles from "./Details.module.scss";
import Loader from "../components/Loader";
import LocationHeader from "../components/LocationHeader";
import ReservationForm from "../components/ReservationForm";
import Extras from "./Extras";


//89Bbbgr9B8U88s77
const ResultsDiv = styled.div`
  width: 100%;
  min-height: 900px;
`;
const DetailsDiv = styled.div`
  width: 100%;
  min-height: 1000px;
  height: auto;
  padding-bottom: 200px;
`;
const Location = styled.div`
  width: 100%;
  color: white;
  background: gray;
  min-height: 100px;
`;
const Details = () => {
  const store = useContext( PlanetContext );
  const [ visible, setVisible ] = useState( true );
  const [ keys, setKeys ] = useState( 1 );

  const onDismiss = () => {

    setVisible( store.showAlert );
    let {showRes2} = store;
    showRes2 = !showRes2;

    store.updateState( {alert: null, showRes2, showAlert: true} )
  };
  return (
      <DetailsDiv>
        <LocationHeader results={store.results}/>


        {store.pickedVan && (
            <>

              <Container>
                <div className={`${styles.cardetails} ${styles.showshadow}`}>
                  <div className={styles.headers}>
                    {" "}
                    <h4> Details</h4>{" "}
                  </div>

                  <Van state={store} v={store.pickedVan}/>
                </div>
              </Container>
              <Container>
                {" "}
                <div className={`${styles.cardetails} ${styles.showshadow}`}>
                  <div className={styles.headers}>
                    {" "}
                    <h4>Extras</h4>{" "}
                  </div>
                  <Extras
                      toggle={store.toggle}
                      extras={store.results.extras}
                      state={store}
                  />
                </div>
              </Container>
              <Container className="mb-5">
                {" "}
                <div className={`${styles.cardetails} ${styles.showshadow}`}>
                  <div className={styles.headers}>
                    {" "}
                    <h4>Driver Information</h4>{" "}
                  </div>
                  {store.showRes2 && (
                      <ReservationForm/>
                  )}
                  {!store.showRes2 && (
                      <ReservationForm/>
                  )}
                </div>
              </Container>
            </>
        )}
        {store.pickedVan && store.loading && (
            <Container className={styles.loading}>
              <Loader/>
            </Container>
        )}


      </DetailsDiv>
  );
};

export default Details;
