import React, { Component } from "react";
import { PlanetContext } from "../containers/PlanetProvider";
import Loader from "../components/Loader";
import { Container } from "reactstrap";
import styled from "styled-components";

import Vans from "./Vans";
import LocationHeader from "../components/LocationHeader";
const StyledContainer = styled(Container)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Location = styled.div`
  width: 100%;
  color: white;
  background: gray;
  min-height: 100px;
`;
const ResultsDiv = styled.div`
  width: 100%;

  min-height: 900px;
  padding-bottom: 200px;
`;

export default class Results extends Component {
  static contextType = PlanetContext;

  render() {
    return (
      <>
        {!this.context.results && this.context.loading && (
          <StyledContainer>
            <Loader />{" "}
          </StyledContainer>
        )}
        {this.context.results &&
          this.context.results.dropoff &&
          !this.context.loading && (
            <ResultsDiv>
              <LocationHeader results={this.context.results} />
              <Vans
                onClick={this.context.onClickReserve}
                results={this.context.results}
              />
            </ResultsDiv>
          )}
      </>
    );
  }
}
