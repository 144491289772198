import React from "react";
import styled from "styled-components";
import Logo from "../img/pvrlogo.png";
import { NavLink, Link } from "react-router-dom";
import { device } from "./device";
const StyledNavbar = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 70px;
  width: 100vw;
  background: #ff6205;
`;
const StyledImg = styled.img`
  width: 90px;
  height: 60px;
  @media ${device.tablet} {
    width: 125px;
    height: 100px;
  }
`;

const StyledNavLink = styled.div`
  color: white;
  font-size: 1.1rem;
  margin: 10px;
  display: flex;
  align-items: center;
  @media ${device.laptop} {
    font-size: 2rem;
    margin: 10px;
  }
`;
const Phone = styled.div`
  color: white;
  font-size: 0.85rem;
  margin: 10px;

  @media ${device.laptop} {
    font-size: 1.3rem;
    margin: 10px;
  }
`;
const Navbar = () => {
  return (
    <StyledNavbar>
      <Link to="/">
        <StyledImg src={Logo} alt="" />
      </Link>
      <StyledNavLink>
        <StyledNavLink>
          <strong>888-2RENTVAN</strong>
        </StyledNavLink>
        <Phone> (888) 273-6882</Phone>
      </StyledNavLink>
    </StyledNavbar>
  );
};

export default Navbar;
