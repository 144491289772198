import React from "react";
import styles from "../Home/Home.module.scss";
// import { Input } from "reactstrap";
// import { Input } from "reactstrap";
const Timepicker = ({ id, name, onChange, time }) => {
  return (
    <>

      <select
        className={styles.styledtime}
        name={name}

        id={id}
        onChange={e => onChange(e, name)}
        value={time}>
        <option value="00:00:00">Midnight</option>
        <option value="00:30:00">00:30 AM</option>
        <option value="01:00:00">01:00 AM</option>
        <option value="01:30:00">01:30 AM</option>
        <option value="02:00:00">02:00 AM</option>
        <option value="02:30:00">02:30 AM</option>
        <option value="03:00:00">03:00 AM</option>
        <option value="03:30:00">03:30 AM</option>
        <option value="04:00:00">04:00 AM</option>
        <option value="04:30:00">04:30 AM</option>
        <option value="05:00:00">05:00 AM</option>
        <option value="05:30:00">05:30 AM</option>
        <option value="06:00:00">06:00 AM</option>
        <option value="06:30:00">06:30 AM</option>
        <option value="07:00:00">07:00 AM</option>
        <option value="07:30:00">07:30 AM</option>
        <option value="08:00:00">08:00 AM</option>
        <option value="08:30:00">08:30 AM</option>
        <option value="09:00:00">09:00 AM</option>
        <option value="09:30:00">09:30 AM</option>
        <option value="10:00:00">10:00 AM</option>
        <option value="10:30:00">10:30 AM</option>
        <option value="11:00:00">11:00 AM</option>
        <option value="11:30:00">11:30 AM</option>
        <option value="12:00:00">Noon</option>
        <option value="12:30:00">12:30 PM</option>
        <option value="13:00:00">01:00 PM</option>
        <option value="13:30:00">01:30 PM</option>
        <option value="14:00:00">02:00 PM</option>
        <option value="14:30:00">02:30 PM</option>
        <option value="15:00:00">03:00 PM</option>
        <option value="15:30:00">03:30 PM</option>
        <option value="16:00:00">04:00 PM</option>
        <option value="16:30:00">04:30 PM</option>
        <option value="17:00:00">05:00 PM</option>
        <option value="17:30:00">05:30 PM</option>
        <option value="18:00:00">06:00 PM</option>
        <option value="18:30:00">06:30 PM</option>
        <option value="19:00:00">07:00 PM</option>
        <option value="19:30:00">07:30 PM</option>
        <option value="20:00:00">08:00 PM</option>
        <option value="20:30:00">08:30 PM</option>
        <option value="21:00:00">09:00 PM</option>
        <option value="21:30:00">09:30 PM</option>
        <option value="22:00:00">10:00 PM</option>
        <option value="22:30:00">10:30 PM</option>
        <option value="23:00:00">11:00 PM</option>
        <option value="23:30:00">11:30 PM</option>
      </select>


    </>
  );
};
export default Timepicker;
