import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
const StyledDiv = styled.div`
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom:20px;

  
`;
const index = () => {
  return   (<Container>
<StyledDiv>We are a customer service driven Van Rental company focused on doing things differently and better then the rest. People who have rented with us already know we are a step above. Come and experience the difference with us.</StyledDiv></Container>)
};

export default index;
