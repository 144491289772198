import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import ModifyReservations from "../ModifyReservations/ModifyReservations";
import FAQ from "../FAQ";
import ContactUs from "../ContactUs";
import AboutUs from "../AboutUs";
import Locations from "../Locations";
import Terms from "../Terms";
import PlanetProvider, { PlanetConsumer } from "../containers/PlanetProvider";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BodyContainer from "./BodyContainer";
import Results from "../Results/Results";
import Details from "../Details/Details";
import Confirmation from "../Confirmation/Confirmation";
import TheBody from "./TheBody";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5BTFD5J",
};
TagManager.initialize(tagManagerArgs);

const Router = () => (
  <BrowserRouter>
    <PlanetProvider>
      <TheBody>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/confirmation" component={Confirmation} />
          <Route exact path="/results" component={Results} />
          <Route exact path="/details" component={Details} />

          <BodyContainer>
            <Route
              exact
              path="/modify-reservations"
              component={ModifyReservations}
            />{" "}
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/locations" component={Locations} />
            <Route exact path="/terms-conditions" component={Terms} />
          </BodyContainer>
        </Switch>{" "}
        <Footer />
      </TheBody>
    </PlanetProvider>
  </BrowserRouter>
);

export default Router;
