import React from "react";
import { Container, Row, Col, CardText } from "reactstrap";
import styles from "./Home.module.scss";
import styled from "styled-components";
import George from "../img/Hank.jpg";
import Holly from "../img/holly150.jpg";
import Jeffersons from "../img/jeffersons150.jpg";
import Anna from "../img/Anna.png";
import Karen from "../img/karen.jpg";
import { device } from "../components/device";
import Tim from "../img/tim.jpg";
import Henry from "../img/Henry185.jpg";
import TopBody from "../components/TopBody";

const Styledh5 = styled.h5`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const Styledh1 = styled.h1`
 font-size: 2rem!important;
  margin-bottom: 30px;
`;
const StyledDiv = styled.div`
  font-size: 1.1rem;
`;
const ImgHeader = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 20px;
    @media ${device.tablet} {
      font-weight: 400;
  font-size: 24px;
  margin-top: 12px;
  margin-bottom: 20px;}
  
`;
const Home = () => {
  return (
      <>
        <TopBody/>
        <Container className={styles.container}>
          <Row>
            <Col>
              <Styledh1>
                <strong>12 & 15 Passenger Van Specialists</strong>
              </Styledh1>
              <StyledDiv>
                12 & 15 Passenger van rental Miami. Lowest price promise - We
                specialize in personalized van rental service for sports teams,
                schools, production companies, churches and other group events.
              </StyledDiv>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styledh5>
                <strong>Our Mission</strong>
              </Styledh5>
              <StyledDiv>
                Our mission is to make your rental experience fast, easy, and
                affordable. We have the largest fleet of minivans, 8-15 seat
                passenger vans, with all our vehicles expertly maintained. When
                you rent a van from Planet Van Rental, rest assured that you’re
                getting top-quality vehicles and the most dedicated services at
                the lowest possible prices. The fact that we offer the lowest
                rates – as well as a generous price-matching policy – simply
                proves we're committed to customer satisfaction. Why pay more when
                you can get the best for less?
              </StyledDiv>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h3 className=" mt-2 mb-3">
                <strong>The Secret of our Success</strong>
              </h3>
            </Col>
            <Col xs="12">
              <StyledDiv>
                Customer service, customer service, customer service. We are
                redefining the way customers should be taken care of in the van
                rental industry. Below is just a few key points of many items that
                makes us a different type of Rental company
              </StyledDiv>
            </Col>
            <Col sm="6">
              <Styledh5>
                <strong>Personalized service</strong>
              </Styledh5>
              <StyledDiv>
                When you book with PlanetVanRentals.com you will be immediately
                assigned a personal advisor that you can contact directly for any
                questions or concerns at any time.
              </StyledDiv>
            </Col>
            <Col sm="6">
              <Styledh5>
                <strong>Delivery</strong>
              </Styledh5>{" "}
              <StyledDiv>
                We understand that sometimes picking up or dropping off rental
                vans is a burden. That’s where we come in. If you contact us in
                advance, we’ll be happy to deliver a van directly to your
                location. (delivery fee may apply) We can also arrange for a
                convenient pick-up if you are unable to visit any of our drop-off
                locations.
              </StyledDiv>
            </Col>
            <Col sm="6">
              <Styledh5>
                <strong>New vehicles</strong>
              </Styledh5>{" "}
              <StyledDiv>
                We are constantly adding new vehicles to our fleet. All our
                vehicles are almost always removed from our fleet before they have
                been in service for 24 months or less.
              </StyledDiv>
            </Col>
            <Col sm="6">
              <Styledh5>
                <strong>Competitive rates</strong>
              </Styledh5>{" "}
              <StyledDiv>
                We are always monitoring the van rental market rates to make sure
                we have the lowest prices. We are so confident in our ability to
                offer the lowest rates we offer our Lowest Rate Promise. If you
                see a lower rate with the same rental days and location and van
                type let us know and we will match the rate plus another 10% off
                if we can verify the other quote.
              </StyledDiv>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h3 className=" mt-2 mb-2">
                <strong>Client Testimonials</strong>
              </h3>
            </Col>
            <Col xs="12" className="mb-2">
              {" "}
              <StyledDiv>
                Don’t take our word for it – here’s what our clients say:
              </StyledDiv>
            </Col>{" "}
          </Row>
          <Row>
            <Col sm="4" className={styles.card}>
              <div>
                <CardText>
                  After many years of searching for a good van rental company, I
                  am happy to say I found the best one and that is
                  PlanetVanRentals! From great customer service experience to
                  vehicle selection and drop off process. Miami branch went above
                  and beyond to accommodate me, making sure my experience was
                  nothing less than perfect. I will highly recommend
                  PlanetVanRentals to many people. Thank you for the great
                  experience. look forward to my next rental with
                  PlanetVanRentals! Best regards, George Flanders.
                </CardText>
              </div>
              <img width="150" src={George} alt=""/>
              <span className='ml-2'>George Flanders</span>
            </Col>
            <Col sm="4" className={styles.card}>
              <div>
                <CardText>
                  Was hands down THE BEST rental car experience I’ve ever had.
                  From the quickness and ease of checking in and out the door. To
                  the absolute best prices around. Had exactly the car we
                  requested. Van was great. Return was just as easy. No pressure
                  to buy insurance. Great great great.
                </CardText>
              </div>
              <img src={Holly} alt=""/>  <span className='ml-2'>Jane Thomas</span>
            </Col>
            <Col sm="4" className={styles.card}>
              <div>
                <CardText>
                  Great staff & went beyond to get my Van Rental and on my way,
                  the shuttle drivers were very nice to wife & me, I will only
                  book with you now but you need to be in more citys, thanks The
                  Jeffersons
                </CardText>
              </div>
              <img width="150" src={Jeffersons} alt=""/>{" "}
              <span className='ml-2'>Hank Jefferson</span>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h3 className=" mt-2 mb-3">
                <strong>Our Team</strong>
              </h3>
            </Col>
            <Col xs="12" className="mb-5">
              {" "}
              <StyledDiv>
                Our team is comprised of talented seasoned individuals with many
                years of experience.
              </StyledDiv>
            </Col>
            <Col sm="3" className={styles.imgradius}>
              <img src={Anna} alt=""/>
              <StyledDiv>
                <i>Anna Goldstien</i>
              </StyledDiv>
              <ImgHeader>Tampa/ Ft. Myers Operations Manager</ImgHeader>{" "}

            </Col>
            <Col sm="3" className={styles.imgradius}>
              <img src={Tim} alt=""/>{" "}
              <StyledDiv>
                <i>Tim Hayes</i>
              </StyledDiv>
              <ImgHeader>South Florida Operations Manager</ImgHeader>{" "}

            </Col>
            <Col sm="3" className={styles.imgradius}>
              <div className={styles.imageholder}>
              <img src={Karen} alt=""/>{" "}
                <StyledDiv>
                  <i>Karen Conrad</i>
                </StyledDiv>
              <ImgHeader>Orlando/North Florida Operations Manager</ImgHeader>{" "}

              </div>
            </Col>

            <Col sm="3" className={styles.imgradius}>
              <img src={Henry} width="100%" alt=""/>{" "}
              <StyledDiv>
                <i>Henry Ballard</i>
              </StyledDiv>
              <ImgHeader>Director of Sales & Marketing</ImgHeader>{" "}

            </Col>
          </Row>
        </Container>
      </>
  );
};

export default Home;
