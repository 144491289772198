import React, { useContext, useState } from "react";
import { PlanetContext } from "../containers/PlanetProvider";
import { Container, Alert } from "reactstrap";
import styles from "../Details/Details.module.scss";
import Loader from "./Loader";
const CCAlert = () => {
  const store = useContext( PlanetContext );


  const onDismiss = () => {
   store.updateState( {alert: null,  showAlert: false} )
  };
  return (
      <div>

        {store.alert &&
        <div className={styles.button_holder}><Alert isOpen={store.showAlert} toggle={onDismiss}
                                                     color="danger">
          <h2> {store.alert}</h2>
        </Alert></div>}
      </div>
  );
};

export default CCAlert;