import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Copyright from "./Copyright";
import { device } from "./device";

const StyledFooter = styled.footer`
  background: #ff6205;
  height: 200px;
  color: white;
  position: absolute;
  bottom: 0;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
`;
const StyledNavLink = styled(NavLink)`
  color: white;
  font-size: 1rem;
  margin: 10px;
  @media ${device.laptop} {
    font-size: 1.5rem;
    margin: 20px;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledNavLink to="/">Home</StyledNavLink>
      <StyledNavLink to="/FAQ">FAQ</StyledNavLink>
      <StyledNavLink to="/modify-reservations">
        Modify Reservation
      </StyledNavLink>
      <StyledNavLink to="/contact-us">Contact Us</StyledNavLink>
      <StyledNavLink to="/about-us">About Us</StyledNavLink>
      <StyledNavLink to="/locations">Locations</StyledNavLink>
      <StyledNavLink to="/terms-conditions">Terms & Conditions</StyledNavLink>
      <Copyright />
    </StyledFooter>
  );
};

export default Footer;
