import React from "react";
import styled from "styled-components";
const StyledBody = styled.div`
  min-height: 1000px;
  height: auto;
  padding-bottom: 200px;
  margin: 30px;

  display: flex;
  align-items: center;
`;
const BodyContainer = props => {
  return <StyledBody>{props.children}</StyledBody>;
};

export default BodyContainer;
