import React from "react";
import styles from "./Confirmation.module.css";
import { Col, Row } from "reactstrap";
const ConfirmationDetails = ({ confirmation }) => {
  return (
    <Col className={styles.container}>
      <div className={styles.bookingtitle}>
        <h2 id="booking-title"> Congratulations! Your Booking is Complete</h2>
      </div>
      <div>
        <div className={styles.confirmationnumber} id="confirmation-title">
          <h4> Your Planet Van Rentals confirmation Number:</h4>
          <div className={styles.confirmationnumber1}>
            {confirmation.resnum}
          </div>
        </div>
      </div>

      <div className={styles.email}>
        <h4>
          {" "}
          A confirmation e-mail has been sent to:
          <span>
            {" "}
            &nbsp;
            {confirmation.details.email}
          </span>
        </h4>
      </div>

      <div className="container ">
        <Row>
          <Col xs={12} md={6}>
            <div className={styles.driver}>
              <strong>Driver</strong>
            </div>
            <div className="driver-name">
              Name: {confirmation.details.firstname}{" "}
              {confirmation.details.lastname}
            </div>
            <div className="driver-email">
              Email: {confirmation.details.email}
              <div className="driver-phone">
                Phone: {confirmation.details.phone}
              </div>
            </div>
          </Col>{" "}
          <Col xs={12} md={6}>
            <div className={styles.total}>
              <h4>
                {" "}
                Your total: <span>${confirmation.details.price}</span>
              </h4>
            </div>{" "}
          </Col>
        </Row>
      </div>
      <div className="container ">
        <Row>
          <Col xs={12} sm={6} className="mt-5 mb-5">
            <div className="">
              <strong>Pick-up</strong>
            </div>
            <div className="pulocation">
              Location:
              {confirmation.plocDetails.name}{" "}
            </div>
            <div className="pudate">
              Date/Time:
              {confirmation.details.pudate} {confirmation.details.putime}
            </div>
          </Col>
          <Col xs={12} sm={6} className="mt-5 mb-5">
            <div className="location-drop ">
              <strong> Drop-off</strong>
            </div>
            <div className="dolocation">
              Location:
              {confirmation.dlocDetails.name}{" "}
            </div>
            <div className="dodate">
              Date/Time:
              {confirmation.details.dodate} {confirmation.details.dotime}
            </div>
          </Col>
        </Row>
      </div>
      <div className="container ">
        <Row>
          <Col xs={12} className="mt-3 mb-5">
            <div className="">
              <strong>Special Instructions</strong>
            </div>
            <div className="pulocation">
              {confirmation.details.specialinstructions}{" "}
            </div>
          </Col>
        </Row>
      </div>
      <div className="add-info showshadow container">
        <div className="text-center payment-details-title hl ">
          <h4>Additional Information</h4>
        </div>
        <div className="row">
          <div className=" add-text col-12 col-lg-12 col-xl-9 col-md-12 offset-xl-1 ">
            <ul>
              <li> Please check your e-mail for booking confirmation. </li>
              <li>
                Your confirmation email will contain all information about your
                reservation and car rental supplier.
              </li>
              <li>
                {" "}
                Don't forget to bring your identification documents and full
                driving license when you pick up your vehicle.
              </li>
              <li>
                {" "}
                We recommend to print your confirmation email and bring it with
                you when you pick up your vehicle.{" "}
              </li>
              <li>
                Read the Terms and Conditions carefully to avoid
                misunderstandings.
              </li>
              <li>
                {" "}
                If you have any questions, please feel free to contact us.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ConfirmationDetails;
