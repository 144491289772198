import React from "react";
import styled from "styled-components";
import { device } from "./device";
const StyledDiv = styled.div`
  height: 70px;
  width: 100%;
  font-size: 1.1rem;
  display: flex;
  color: white;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  /* position: absolute; */
  bottom: 10px;
  @media ${device.laptop} {
    font-size: 1.5rem;
  }
`;

const Copyright = () => {
  return <StyledDiv>Planet Van Rentals &copy; 2017-2019</StyledDiv>;
};

export default Copyright;
