import React from "react";
import { Container } from "reactstrap";
const index = () => {
  return   (<Container>
    <div className="p2text">
      <span className="p2txtheading">Terms and Conditions	</span>The following are terms and conditions applicable to your reservation and rental with Planet Van Rentals (the Company or Owner).
    </div>
    <div className="p2text">
      <span className="p2txtheading">Age Requirements</span>
      Renter must be 25 years of age with a valid driver’s license and a major credit card in his/her own name. Renters 21 through 25 years of age with a major credit card in his/her name may rent provided they meet all required standard rental qualifications..
    </div>    <div className="p2text">
    <span className="p2txtheading">Geographic Restrictions</span>
    Vehicles shall not be driven out of the state of Florida.  Vehicles taken outside the state of Florida shall incur a $400.00 out of state fee or 29 cents per mile whichever is greater.
  </div>    <div className="p2text">
    <span className="p2txtheading">Credit Card Policies</span>
    The credit card must have the same name as the renter's driver's license. The following are accepted forms of payment:
    - Visa
    - MasterCard
    - American Express
    - Discover
    - Debit Card

  </div>    <div className="p2text">
    <span className="p2txtheading">CREDIT AND DEBIT CARD HOLD: </span>A minimum authorization of $250.00 will be authorized against your credit card at the time of rental as a security deposit. Unused authorizations will be released upon return of the vehicle when the final charges are settled after audit.
  </div>    <div className="p2text">
    <span className="p2txtheading">Credit or Debit Card</span>Renter authorizes Company to reserve credit with the card issuer in an amount equal to all estimated charges including $250.00 security deposit. Company may bill Renter’s card issuer at time of reservation or upon delivery of the vehicle or upon late return. Renter further authorizes company to submit all charges due related to the rental transaction and as provided herein, for payment to any credit card or debit card issuer, if such presented as a means of deposit or security at the time vehicle was rented or reserved or returned, This includes where third party to whom billing was directed refuses to make
    payment. Company is authorized to process charges electronically.

  </div>    <div className="p2text">
    <span className="p2txtheading">Driver's License Requirements</span>At time of pick up, Renter must present a valid, U.S.-issued driver's license and credit card (or debit card at participating locations) in the Renter's name which includes a photograph of the customer. If the driver's license is not U.S.-issued, the driver must present his/her driver's license including a photograph and home address and passport from the same country as the driver's license or Canadian residents may present a Canadian enhanced license in the driver's name. Digital licenses are not accepted. Other Requirements: The driver's license must be valid for the entire rental period.  Learner’s Permits” are not accepted. Any license which, on its face, restricts the licensee to the use and operation of a vehicle equipped with a form of a breathalyzer apparatus is not accepted. Temporary Driver’s Licenses may be refused if the renting location is unable to otherwise verify the customer’s identity or verify the authenticity of the temporary license.  Additional Government-issued identification may be required.
  </div>    <div className="p2text">
    <span className="p2txtheading">Rental Qualifications </span>Renter understands Renter may not rent if Renter does not satisfy the Company’s current standard rental qualifications.
  </div>    <div className="p2text">
    <span className="p2txtheading">Authorized Drivers</span>Renter represents that Renter is a capable and validly licensed driver, 25 years of age or older. The Vehicle shall NOT be operated by anyone except Renter, and the following Additional Authorized Drivers who are capable and validly licensed drivers, 25 years of age or older and have the Company’s prior permission to drive the Vehicle by having signed the Rental documents as an Additional Authorized Driver after qualification by the Company (hereinafter also referred to as Renter). Renter and Additional Drivers may be referred to as Authorized Driver(s).
  </div>    <div className="p2text">
    <span className="p2txtheading">Vehicle </span>
  </div>    <div className="p2text">
    <span className="p2txtheading">Renter understands that Owner/Company owns the Vehicle or leases the Vehicle. The Vehicle includes tires, tools, equipment, accessories, keys and Vehicle documents. The vehicle is delivered to Renter in good operating condition solely for rental purposes. Renter understands no one but Company can transfer the Vehicle. Attempted transfer by Renter or anyone else is void. No one may service or repair the Vehicle without Company’s prior approval.</span>
  </div>    <div className="p2text">
    <span className="p2txtheading">Optional Coverages</span>Collision Damage Waiver (CDW) is optional. If you accept full CDW by your initials on the rental document at the daily rate, for each full or partial day that the vehicle is rented, and the vehicle is operated in accordance with the agreement, the Company assumes all loss or damage to the vehicle except for lost, damaged or stolen keys or remote entry devices, tools, towing or tire service unless related to an accident, or recovery of the vehicle if stolen, and except by the amount of responsibility, if any, specified on the rental document.

    If Renter acknowledges and confirms that he or she carries valid and transferrable physical damage insurance coverage and if Renter has accepted the INSURANCE DEDUCTIBLE WAIVER, Renter agree to pay the rate shown in consideration for which the Company will waive renter’s insurance deductible ONLY but not liability or insurance coverage for property damage. The Company reserves the right to recover from the Renter or Additional Driver's insurance company for all property damage. Renter and/or Additional Driver will be responsible for all damages to the vehicle if the insurance company fails to promptly compensate the Company.

    The protection provided by IDW may duplicate the renter's existing coverage. Planet Van Rentals is not qualified to evaluate the adequacy of the renter's existing coverage, therefore, Renter should examine his or her credit card protections, automobile insurance policies or other sources of coverage which may duplicate the protection provided by IDW.

    If Renter does not accept either CDW or IDW, Renter shall owe for all loss or damage to the vehicle. There shall be no coverage CDW or IDW beyond the initial rental period unless no damage exists and the Company agrees and full payment is made.

    The following shall invalidate Collision Damage Waiver or Insurance Deductible Waiver: if Vehicle is damaged when used or driven: by any person other than Renter or Additional Authorized Drivers without Owner's prior written consent or by any person if there is reasonable evidence the driver was impaired by the use of alcohol, narcotics, intoxicants, or drugs, used with or without a prescription; by any person committing a felony or otherwise engaged in a criminal act; in a race or speed contest; to tow or push anything; outside the states authorized; under authority of any license that is suspended, revoked, invalid or does not belong to the driver; to transport persons or property for hire; in a wanton or reckless manner or if Vehicle is deliberately damaged; on an unpaved road or off road; to transport explosives, chemicals, corrosives or other hazardous materials or pollutants of any kind; or if Renter misrepresents facts to Owner pertaining to rental, use, or operation of Vehicle; or if Vehicle's interior components are stolen or damaged when Vehicle is unlocked or keys are not secured; or if Renter fails or refuses to provide Owner, police, or other authorities with a full report of any accident or vandalism involving Vehicle or otherwise fails to cooperate with Owner, police, or other authorities in the investigation of any accident or vandalism.

    If Vehicle is stolen and Renter fails to do any of the following: return the original ignition keys and Owner's key tag identifying Vehicle; file a police report within 24 hours after discovering the theft; cooperate fully with Owner, police and other authorities in all matters connected with the investigation of the theft; ensure that the Vehicle's ignition is turned off at the time the Vehicle is stolen.

  </div>    <div className="p2text">
    <span className="p2txtheading">Vehicle Returns</span>Renter agrees to return the Vehicle to Company in the same condition in which Renter received it, except for ordinary wear and tear. Renter agrees to return the Vehicle to Company location, either on the due date and time specified on the Rental document or upon Company demand. If for any reason Renter cannot return the Vehicle at the time and location required by this Agreement including the reason that Renter gave the Vehicle to another Authorized Driver or any other person, who has failed to return the Vehicle to Renter, Renter will pay for all loss, or estimated damages to Vehicle including loss of
    use, claim processing fees and administrative charges, and attorneys’ fees, as permitted by law.

  </div>    <div className="p2text">
    <span className="p2txtheading">
Vehicle Repossession
</span>Company may repossess the Vehicle without demand at Renter’s expense if the Vehicle is found illegally parked,  apparently abandoned, used in violation of law or of this Agreement, or for a Prohibited Use with such charges being additional charges to the rental agreement upon final audit.
  </div>    <div className="p2text">
    <span className="p2txtheading">Prohibited Uses</span>Renter agrees that the Vehicle shall NOT be used by or for any of the following PROHIBITED USES, subject to applicable law: by an unauthorized driver; by any driver under the influence of intoxicants, drugs, or any other substance known to impair driving ability; for any illegal purpose; outside the state; by anyone who gives the Company a false name, address, age or other false or misleading information; in any abusive or reckless manner or careless driving; to carry persons or property for hire; to tow or push anything; to carry passengers in excess of number of seatbelts; in any
    race, test, contest, or training activity; on unpaved roads; use in violation of any law of ordinance or
    regulation governing use or return thereof; use in violation of any provision of this agreement; leaving the
    vehicle and failing to remove the keys and the vehicle is stolen;operating vehicle beyond return date.
    Renter understands that if the vehicle is obtained or used for any prohibited use or in violation hereof, the
    Renter’s right to operate shall terminate and Renter shall be required to and shall agree to cease using said
    vehicle and to notify Company and to pay all expenses incurred by Company in returning vehicle to place of
    rental and agrees any continued operation after violation of this agreement without knowledge, consent or
    authorization of Company, authorizes Company to notify police said vehicle has been stolen and Renter
    releases and discharges Company from any liability and all claims of any nature arising therefrom.
    Company has the right to seize without legal process, or notice to Renter, said vehicle at any time or place
    and Renter waives all claims for damages connected with such seizure. Renter shall be fully responsible for
    all towing, storage, and repossession costs, loss and resulting damages, including loss of use, claims
    processing fees, administrative charges, costs and attorney's fees also, where permitted by law.

  </div>    <div className="p2text">
    <span className="p2txtheading">Payment</span>No refund or credit or modification of prepaid rentals Renter agree to pay Company upon demand: All rates, charges, taxes, airport fees, including charges.  for miscellaneous service and equipment, service charge for keys damaged, broken, lost or locked in the Vehicle, out of state fees, and all other amounts incurred as a result of this rental transaction and all charged due upon final audit; Vehicle Damage-Regardless of fault, all loss or estimated damage to Vehicle,
    including loss of use, claims processing fees and administrative charges, as permitted by law, while renting
    under this Agreement. If loss or damage to Vehicle are covered by insurance, payment by the insurer within
    the time limits prescribed by applicable law is acceptable and prior payment be Renter may not be required.
    Renter is responsible for and agrees to pay Company the fair market value for replacing the rented vehicle
    and/or repairing damage to the car; Collection and Vehicle Recovery Expenses. All expenses of any
    kind incurred as a result of collecting amounts due or for recovery of Vehicle, including towing, storage, repo,
    and reasonable attorney's fees; Fines and Penalties. Fines and Penalties arising from use of the Vehicle.

  </div>    <div className="p2text">
    <span className="p2txtheading">All charges are subject to final audit. </span>Renter authorizes any and all charges due, including but not limited to, additional daily rental and service charges, deductibles, fines, fees, penalties, claims, traffic, parking tickets, handling charges for processing and paying tickets/traffic violations, damages, costs, and out of state fees, to be made and paid by the method used at the time of reservation, rental or return.
  </div>    <div className="p2text">
    <span className="p2txtheading">Fuel Policy</span>Vehicle is rented with a full tank. Renter may bring the vehicle back full to avoid a charge or the Company will refuel the vehicle based on the miles driven or level of the tank. A refueling service fee will apply per gallon. The refueling service charge will be determined as follows: If the tank is not full at return, this charge will be the prevailing per gallon rate multiplied by an estimate of the number of gallons required to refill the tank. This estimate is arrived at by using the manufacturer's specified tank capacity for the Vehicle and then determining the capacity of the portion of the tank that is empty as indicated by the Vehicle's fuel gauge.
  </div>    <div className="p2text">
    <span className="p2txtheading">Fuel Policy</span>Vehicle is rented with a full tank. Renter may bring the vehicle back full to avoid a charge or the Company will refuel the vehicle based on the miles driven or level of the tank. A refueling service fee will apply per gallon. The refueling service charge will be determined as follows: If the tank is not full at return, this charge will be the prevailing per gallon rate multiplied by an estimate of the number of gallons required to refill the tank. This estimate is arrived at by using the manufacturer's specified tank capacity for the Vehicle and then determining the capacity of the portion of the tank that is empty as indicated by the Vehicle's fuel gauge.
  </div>    <div className="p2text">
    <span className="p2txtheading">Debit Card Policies</span>Debit cards are accepted. A minimum authorization of $250.00 will be assessed against your debit card at the time of rental. The Company releases the hold on the funds when the vehicle is returned, however, these funds may no longer be available for immediate use and may take up to 14 days, depending on issuing bank, to become available.

    The Company will not be responsible for any returned check or insufficient fund charges or bank fees related to this process. If the debit card fails to meet debit card criteria, Renter may either be unable to rent a vehicle or required to present a major credit card in Renter’s name to qualify for a rental.

  </div>    <div className="p2text">
    <span className="p2txtheading">Toll Option Program</span>Toll Option program is Planet Van Rentals electronic toll program which allows renters to drive through cashless or electronic toll lanes.  If renter declines the prepaid toll option, renter agrees to pay all tolls incurred during the rental period and any extended period and an administrative processing fee of $25.00 plus actual tolls for each of a toll event,
  </div>
    <div className="p2text">
      <span className="p2txtheading">After Hours Service</span>After hours drop off may be accommodated with advance notice to coordinate same.
    </div>  <div className="p2text">
    <span className="p2txtheading">Fines and Penalties </span>Renter will pay all fines, penalties and costs imposed for parking or traffic violations with respect to the Vehicle while rented under this Agreement. Renter will promptly report such violations to Company and will indemnify and hold Company harmless from all claims and costs arising out of such violations, including expenses in connection with the handling of such matters. Renter agree that all charges, fines, penalties and handling costs arising from parking or traffic violations may be billed to the credit card Renter used at the time of reservation, deposit, rental or payment.
  </div>  <div className="p2text">
    <span className="p2txtheading">
Repairs
</span>Renter agrees that Renter will be responsible for unauthorized repairs and not be reimbursed. Renter understands that authorized repairs will be reimbursed only with invoices and payment receipts
  </div>  <div className="p2text">
    <span className="p2txtheading">Seat Belt and Child Restraint Laws</span>Renter understands that it is the responsibility of all Authorized Drivers to comply with all applicable
    seat belt and child restraint laws. The Authorized Driver is responsible for the protection of all children under
    the age of five (5) by properly using a child passenger restraint system that complies with the Federal Motor
    Vehicle Safety Standards. The Authorized Driver must supply this child restraint system or accept it as an additional option.

  </div>



  </Container>)
};

export default index;
