import React, { Component } from "react";
import PlanetProvider, { PlanetContext } from "../containers/PlanetProvider";
import styles from "../Details/Details.module.scss";
import styled from "styled-components";
import valid from 'card-validator';
import safe from "../img/safe.png";
import Cleave from 'cleave.js/react';
import CCAlert from "./CCAlert";
import { Modal, ModalHeader, ModalBody, Container, } from "reactstrap";

import { Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
import Loader from "./Loader";

const StyledButton = styled.button`
  background: #ff6205 !important;
  font-size: 30px !important;
  font-weight: 500;
  width: 45%;
  color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  &:disabled {
     background: #ff6205 !important;
        opacity: .75;
        font-size: 20px !important;}
`;
const StyledButton1 = styled.button`
  background: #ff6205 !important;
  font-size: 30px !important;
  font-weight: 500;
  width: 85%;
  color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  &:disabled {
     background: #ff6205 !important;
        opacity: .75;
        font-size: 20px !important;}
`;
export default class ReservationForm extends Component {


  constructor(props) {
    super( props );
    this.state = {
      modal: false,
      expYear: "",
      expMonth: "",
      cardCode: "", exp: "",
      cardNumber: "",
      expValid: false,
      expMonthValid: false,
      cardCodeValid: false,
      cardNumberValid: false,paynow:false
    };

    this.onCreditCardChange = this.onCreditCardChange.bind( this );
    this.onCreditCardFocus = this.onCreditCardFocus.bind( this );
    this.myRef = React.createRef();
    this.responseHandler=this.responseHandler.bind(this)
    this.togglecc = this.togglecc.bind( this );
        this.toggle = this.toggle.bind( this );
  }

  componentDidMount() {

    // const script = document.createElement( "script" );
    // script.async = true;
    // document.head.appendChild( script );
    // script.src = "https://js.authorize.net/v1/Accept.js"
    // document.head.appendChild( script );
    window.responseHandler = this.responseHandler
  }

  onCreditCardChange(e) {

    const value = e.target.value;
    const rawValue = e.target.rawValue
    const cardNumber = rawValue;
    this.setState( {cardNumber, cardNumberValid: valid.number( value ).isValid} );


  }

togglecc(e){
  e.preventDefault()
    this.setState({paynow:!this.state.paynow})
}
  onCreditCardFocus(event) {
    // update some state
  }

  keyPress(e) {


    if (e.keyCode == 8 || e.keyCode == 46) {
      const field = e.target.name;
      const oldvalue = this.state[field]

      const newvalue = oldvalue.substr( 0, oldvalue.length - 1 );
      this.setState( {[field]: newvalue} )
    }
  }

  setValue = (field, e) => {
    const old = this.state[field];
console.log('----------------------------')
console.log(field)
console.log('----------------------------')

    const object = {};
    const value = e.target.value
    const fvalue = value.replace( /[^0-9\.]/g, "" );
    object[field] = fvalue
    if (field != 'cardCode' && old.length < 2) {

      this.setState( object );

    }

    if (field == 'cardCode' && old.length < 4) {


      this.setState( object );
    }


    if (field == 'cardNumber') {
      this.setState( {cardNumberValid: valid.number( value ).isValid} )
    }

if (field ==='expMonth'&& e.target.value.length===2){
  console.log('----------------------------')
  console.log(e.target.value.length===2)
  console.log('----------------------------')
  this.myRef.current.focus();}
  };


  responseHandler(response) {
    console.log( '---------inside class-------------' )
    console.log( response )
    console.log( '----------------------------' )
    console.log( '----------------------------' )
    console.log( response.messages.resultCode )
    console.log( '----------------------------' )
    if (response.messages.resultCode == "Ok") {
      console.log( '----------------------------' )
      console.log( 'inside reservationcall' )
      console.log( '----------------------------' )

      this.context.makeReservation( response );
      console.log('--------response3---------------')
      console.log(response)
      console.log('----------------------------')

    }


    if (response.messages.resultCode === "Error") {
      let i = 0;
      while (i < response.messages.message.length) {
        console.log(
            response.messages.message[i].code + ": " +
            response.messages.message[i].text
        );
        i = i + 1;
      }

    }
  }

  toggle() {

    this.setState( prevState => ({
      modal: !prevState.modal,
    }) );
  }

  static contextType = PlanetContext;

  sendPaymentDataToAnet() {

    var authData = {};
    authData.clientKey = "45hzncWg4ZNR8mkdW35cz83PvGSq4g55n6Vt7QtCM6gUgmNw6LxCR6vvqa75R325";
    authData.apiLoginID = "5fY39fYS";

    var cardData = {};
    cardData.cardNumber = this.state.cardNumber.toString();
    cardData.month = this.state.expMonth.toString();
    cardData.year = this.state.expYear.toString()
    cardData.cardCode = this.state.cardCode.toString();
    console.log( '----------cardata---------' )
    console.log( cardData )
    console.log( '----------------------------' )
    var secureData = {};
    secureData.authData = authData;

    console.log( '----------authdata--------------' )
    console.log( authData )
    console.log( '----------------------------' )
    console.log( '----------------------------' )

    console.log( '----------------------------' )
    secureData.cardData = cardData;


      window.Accept.dispatchData( secureData, this.responseHandler );



  }

  handleSubmit = e => {
    e.preventDefault();

    const paylater = e.target.name === 'paylater'
    const paynow = e.target.name !== 'paylater'


    const firstname = this.context.firstName;
    const lastname = this.context.lastName;
    const email = this.context.email;
    const phone = this.context.phone;

    if (
        firstname.length > 0 &&
        lastname.length > 0 &&
        email.length > 0 &&
        phone.length > 0
    ) {

      if (paynow) this.sendPaymentDataToAnet()

      if (paylater) this.context.handleReserveButton( e );
    }
    if (
        firstname.length === 0 ||
        lastname.length === 0 ||
        email.length === 0 ||
        !phone.length === 0
    ) {
      this.toggle();
    }
  };

  render() {
    return (
        <Form id="paymentForm"
              method="POST"
              action="https://autorentalgateway.com/planet/checkout">

          <input type="hidden" name="dataValue" id="dataValue"/>
          <input type="hidden" name="dataDescriptor" id="dataDescriptor"/>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input
                    bsSize="md"
                    value={this.context.firstName}
                    onChange={this.context.setValue.bind( this, "firstName" )}
                    type="text"
                    name="firsttname"
                    id="firstname"
                    placeholder="First Name"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                    bsSize="md"
                    value={this.context.lastName}
                    onChange={this.context.setValue.bind( this, "lastName" )}
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input
                    bsSize="md"
                    value={this.context.email}
                    onChange={this.context.setValue.bind( this, "email" )}
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                    bsSize="md"
                    value={this.context.phone}
                    onChange={this.context.setValue.bind( this, "phone" )}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                />
              </FormGroup>
            </Col>{" "}
            <Col sm={12} className={styles.textarea}>
              <FormGroup>
                <Label for="special">Special Instructions:</Label>
                <Input
                    type="textarea"
                    onChange={this.context.setValue.bind(
                        this,
                        "specialinstructions",
                    )}
                    value={this.context.specialinstructions}
                    name="specialInstructions"
                    id="special"
                />

              </FormGroup>
            </Col>
          </Row>{" "}
          <div className='d-flex justify-content-around'>
            <StyledButton  onClick={this.togglecc}>
             Pay Now
            </StyledButton>
            <StyledButton name='paylater' onClick={this.handleSubmit}>
              Pay Later
            </StyledButton>
          </div>



          <Row className={this.state.paynow?'d-flex':'d-none'} form>

            <div className={`${styles.ccinfo} `}>
              {/*<div className={styles.ccheader}>*/}
              {/*  {" "}*/}
              {/*  <h4>Credit Card Information</h4>{" "}*/}
              {/*</div>*/}
              <div className={styles.alert1}>
<CCAlert/>
              </div>
              <div className={styles.ccform}>
                <div className={styles.ccimg}>

                  <div className={styles.paypal}>
                    {/* <img src={paypal} alt="" /> */}
                    <img src={safe} alt=""/>
                  </div>
                  <div className={styles.ccimg1}>
                    <div className={styles.norton}>
                      <img
                          src="https://compareautorentals-images.s3.amazonaws.com/NortonLogo.png"
                          id="nortonseal"
                          alt=""
                      />
                    </div>
                    <div className={styles.mcafee}>
                      {" "}
                      <img
                          src="https://compareautorentals-images.s3.amazonaws.com/mcafee.png"
                          alt=""
                          id="mcafeeseal"
                      />{" "}
                    </div>
                  </div>
                </div>
                <Col md={6} sm={12} className={styles.ccholder}>

                  <Row className={styles.ccholder1}>
                    <div className={styles.ccnumber}>
                      <div className={styles.ccnumbertxt}>Card Number:&nbsp;</div>
                      <Cleave placeholder="Credit Card No."
                              options={{creditCard: true}}
                              onFocus={this.onCreditCardFocus}
                              onChange={this.onCreditCardChange}
                              className={this.state.cardNumberValid ? `${styles.valid} form-control-lg form-control` : `${styles.notvalid} form-control-lg form-control`}
                              name="cardNumber"
                              id="cardNumber"
                              value={this.state.cardNumber}/>

                    </div>
                    <div className={styles.ccv}>
                      <div className={styles.cvvtext}>CVV:</div>
                      <Input
                          bsSize="md"
                          value={this.state.cardCode}
                          onChange={this.setValue.bind( this, "cardCode" )}
                          onKeyDown={this.keyPress.bind( this )}
                          type="text"
                          name="cardCode"
                          id="cardCode"
                          placeholder="CVV"
                      />
                    </div>
                  </Row>
                  <Row className={styles.ccholder1}>

                    <div className={styles.ccexp}>
                      <div className={styles.exptext}>Expiration:&nbsp;</div>
                      <div className={styles.ccmonth}>

                        <Input
                            bsSize="md"
                            value={this.state.expMonth}
                            onChange={this.setValue.bind( this, "expMonth" )}
                            onKeyDown={this.keyPress.bind( this )}
                            type="text"
                            name="expMonth"
                            id="expMonth"
                            placeholder="MM"
                        />

                        <Input
                            bsSize="md"
                            value={this.state.expYear}
                            onChange={this.setValue.bind( this, "expYear" )}
                            onKeyDown={this.keyPress.bind( this )}
                            type="text"
                            name="expYear"
                            id="expYear"
                            placeholder="YY"
                            ref={this.myRef}
                        />
                      </div>

                    </div>

                  </Row>
                </Col>
              </div>
            </div>

          </Row>
          <Row form className={this.state.paynow?'w-100 d-flex justify-content-center':'d-none'}>
            <StyledButton1 disabled={this.context.loading1} onClick={this.handleSubmit}>
              {this.context.loading1?`Processing`: `Confirm`}
            </StyledButton1>

          </Row>





          <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              centered
              className={this.props.className}>
            <ModalHeader toggle={this.toggle}>
              <h2> Missing Required Information</h2>
            </ModalHeader>
            <ModalBody>
              <h3>
                {" "}
                Firstname, lastname, email and phone are required to make a
                reservation
              </h3>
            </ModalBody>
          </Modal>
        </Form>
    );
  }
}
