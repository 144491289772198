import React from "react";
import styled from "styled-components";
import styles from "./Results.module.scss";
import check from "../img/chkmark.jpg";
import { Container } from "reactstrap";
import { device } from "../components/device";
const ResultsBox = styled.div`
  width: 100%;
  height: 455px;
  border: 1px solid #e6e7eb;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  padding-right: 10px;
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  @media ${device.tablet} {
    width: 100%;
    height: 250px;
    border: 1px solid #e6e7eb;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
    padding-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
`;
const ReserveButton = styled.button`
  background: #ff6205 !important;
  height: 60px;
  font-size: 30px;
  width: 72%;
  color: white;
  font-weight: 800;
  border-radius: 8px;

  @media ${device.tablet} {
    background: #ff6205 !important;
    height: 100%;
    font-size: 30px;
    width: 100%;
    color: white;
    font-weight: 800;
  }
`;
const Inc = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  
`;
const size = {
  FVAR: "15 Passenger Van",
  IVAR: "12 Passenger Van",
  SVAR: "11 Passenger Van",
};
const Vans = ({ results, onClick }) => {

  const Van = results.vehicles
    .sort((a, b) => a.price - b.price)
    .map((v, i) => {
      const daily=Math.floor(v.price / v.rentaldays)
      const btnmessage= daily>500?'Sold Out':'Reserve'
      const disabled= daily>500?true:false;
      const inclusions = v.inclusions.map((inc, b) => {
        return (
          <div key={b} className={styles.inc}>
            <img src={check} alt="" />
            {inc}
          </div>
        );
      });

      return (
        <ResultsBox key={i}>
          <div className={styles.acriss}>
            <div className={styles.sizeheader}>{size[v.acriss]}</div>{" "}
            <div className={styles.sizename}> {v.vehName}</div>
          </div>
          <div className={styles.image}>
            {" "}
            <img src={v.url} alt="" />
          </div>
          <div className={styles.details}>
            <div className={styles.priceday}>
              US$
              {daily}
              <span>/Day</span>
            </div>
            <div className={styles.priceholder}>
              Total w/taxes & fees:
              <span>
                {v.price.toLocaleString(
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                  {
                    style: "currency",
                    currency: "USD",
                  },
                )}
              </span>
            </div>
            <div className={styles.included}>
              {" "}
              <Inc>Included In Price:</Inc> {inclusions}
            </div>
            <div className={styles.resbtn}>
              <ReserveButton disabled={disabled} onClick={e => onClick(e, v)}>
                {btnmessage}
              </ReserveButton>{" "}
            </div>
          </div>
        </ResultsBox>
      );
    });

  return <Container className={styles.container}>{Van}</Container>;
};

export default Vans;
