import React from "react";
import styled from "styled-components";
import VanImg from "../img/vanheader.png";
import SelectBox from "./SelectBox";
import { device } from "./device";
const StyledBody = styled.div`
  min-height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    background-image: url(${VanImg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 800px;
  }
`;
const TopBody = props => {
  return (
    <StyledBody>
      <SelectBox />
      {props.children}
    </StyledBody>
  );
};

export default TopBody;
