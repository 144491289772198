import React from "react";
import styled from "styled-components";
import { Container } from "reactstrap";
import { PlanetConsumer } from "../containers/PlanetProvider";
import Van from "../Results/Van";
import LocationHeader from "../components/LocationHeader";

import ConfirmationDetails from "./ConfirmationDetails";

const DetailsDiv = styled.div`
  width: 100%;
  padding-bottom: 200px;
  min-height: 1000px;
`;
const Location = styled.div`
  width: 100%;
  color: white;
  background: gray;
  min-height: 100px;
`;
const Confirmation = () => {
  return (
    <DetailsDiv>
      <PlanetConsumer>
        {context =>
          context.pickedVan &&
          context.resConfirmation && (
            <>
              <LocationHeader results={context.results} />
              <Container>
                <Van state={context} v={context.pickedVan} />
              </Container>
              <Container>
                <ConfirmationDetails confirmation={context.resConfirmation} />
              </Container>
            </>
          )
        }
      </PlanetConsumer>
    </DetailsDiv>
  );
};

export default Confirmation;
