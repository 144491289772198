import React from "react";
import styled from "styled-components";
const Body = styled.div`
  /* margin: 0;
  padding: 0; */

  height: 100%;
  width: 100%;
  position: relative;
`;
const TheBody = props => {
  return <Body>{props.children}</Body>;
};

export default TheBody;
